import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import errorImg from 'shared-ui/assets/img/error/maintenance.svg'

const MaintenancePage = props => {
  const { pageTitle, pageSubtitle } = props
  return (
    <React.Fragment>
      <Grid container spacing={24} style={{ marginTop: '96px' }}>
        <Grid item xs={12} align={'center'}>
          <img src={errorImg} alt="" style={{ maxWidth: '384px' }} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="title" align={'center'}>
            {pageTitle}
          </Typography>
          <Typography variant="subheading" align={'center'}>
            {pageSubtitle}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const styles = ({ spacing: { unit } }) => ({
  root: {},
  containerImg: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& >*+*': {
      marginTop: unit * 2,
    },
  },
})

export default withStyles(styles)(MaintenancePage)
