import React from 'react'
import 'shared-ui/assets/styles/global.css'
import App from './App'
import { createRoot } from 'react-dom/client'
import { msalConfig } from './auth/AuthConfig'
import { PublicClientApplication } from '@azure/msal-browser'

const errorsToWarn = ['Warning:']
const oldConsError = console.error

console.error = function (...args) {
  let toWarn = typeof args[0] === 'string' && errorsToWarn.some(s => args[0].startsWith(s))
  toWarn ? console.warn(...args) : oldConsError(...args)
}
const apiUrl = process.env.REACT_APP_API_URL

fetch(apiUrl + '/msalconfig', { credentials: 'include' })
  .then(async response => {
    const data = await response.json()
    const { auth, msLoginEnabledB2C = false } = data || {}
    const MSALConfig = { ...msalConfig, auth }
    const msalInstance = msLoginEnabledB2C && new PublicClientApplication(MSALConfig)

    const root = createRoot(document.getElementById('root'))
    root.render(<App msalInstance={msalInstance} data={data} />)
  })
  .catch(err => {
    console.error('Error fetching msalconfig', err)
    const root = createRoot(document.getElementById('root'))
    root.render(<App />)
  })
