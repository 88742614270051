import React, { useEffect, useMemo, useState } from 'react'
import { MsalAuthenticationTemplate, MsalProvider, useIsAuthenticated } from '@azure/msal-react'
import { BrowserUtils, EventType, InteractionType } from '@azure/msal-browser'
import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'
import { jwtDecode } from 'jwt-decode'
import MaintenancePage from '~components/pages/MaintenancePage'

const pathToRegexp = require('path-to-regexp')
const loginPath = '/login'

const MsalB2CAuthProvider = ({ instance, data = {}, children, api }) => {
  const { auth, msLoginEnabledB2C } = data

  const [decodedToken, setDecodedToken] = useState()
  const [token, setToken] = useState()

  const [isInitialised, setIsInitialised] = useState(false)

  const bypass = useMemo(() => {
    return data.whitelistedPaths?.some(x => {
      const { route } = x
      if (window.location.pathname === loginPath) return false
      const result = pathToRegexp(route)
      return result.test(window.location.pathname)
    })
  }, [data.whitelistedPaths])

  useEffect(() => {
    if (!msLoginEnabledB2C) return

    const fetchConfig = async () => {
      try {
        if (!instance.getActiveAccount() && instance.getAllAccounts().length > 0) {
          // Account selection logic is app dependent. Adjust as needed for different use cases.
          instance.setActiveAccount(instance.getAllAccounts()[0])
        }

        instance.enableAccountStorageEvents()
        const callbackId = instance.addEventCallback(event => {
          if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
              event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
            event.payload.account
          ) {
            instance.setActiveAccount(event.payload.account)
            const { idToken } = event.payload.account
            const decoded = jwtDecode(idToken)
            setToken(idToken)
            setDecodedToken(decoded)
          }
        })

        await instance.initialize()
        setIsInitialised(true)

        return () => {
          if (callbackId) {
            instance.removeEventCallback(callbackId)
          }
        }
      } catch (error) {
        console.error('Failed to fetch MSAL config:', error)
      }
    }
    void fetchConfig()
  }, [])

  if (!msLoginEnabledB2C) return children
  if (!isInitialised) return null

  api.connector.setupMsal(instance)
  api.connector.setupMsalTokenCreator(async () => {
    if (bypass) return null
    const now = Math.floor(Date.now() / 1000)
    if (now < decodedToken?.exp) return token
    const response = await instance.acquireTokenSilent({
      account: instance.getActiveAccount(),
    })

    return response.idToken
  })

  api.connector.setupMsalLogout(() => {
    if (instance) {
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      })
    }
  })

  return (
    <MsalProvider instance={instance}>
      <Authenticator config={auth} data={data} bypass={bypass}>
        {children}
      </Authenticator>
    </MsalProvider>
  )
}

const Authenticator = ({ data, children, bypass }) => {
  // const isAuthenticated = useIsAuthenticated()

  const { templateApiUrl } = data

  const authRequest = {
    extraQueryParameters: { url: templateApiUrl },
  }

  if (bypass && window.location.pathname !== loginPath) {
    return children
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={() => (
        <MaintenancePage
          pageTitle="MSAL"
          pageSubtitle={'Error authenticating with MSAL Provider'}
        />
      )}
      loadingComponent={LoadingComponent}
    >
      {children}
    </MsalAuthenticationTemplate>
  )
}

const LoadingComponent = () => {
  return (
    <div className={'w-full'}>
      <Fade in={true} unmountOnExit timeout={500}>
        <LinearProgress color={'secondary'} />
      </Fade>
    </div>
  )
}

export default MsalB2CAuthProvider
