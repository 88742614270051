import React from 'react'
import { Route, Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

import AppFrame from './components/organisms/AppFrame'
import PopupWrapper from 'shared-ui/components/popup/PopupWrapper'
import PageWrapper from 'shared-ui/components/pages/PageWrapper'
import { createBrowserHistory } from 'history'
import { ApiProvider } from 'shared-ui/api/ApiContext'
import SnackMessages from 'shared-ui/components/molecules/SnackMessages'
import ErrorHandler from 'shared-ui/components/pages/ErrorHandler'

import ApiConnector from 'shared-ui/api/ApiConnector'
import PortalApi from './api/PortalApi'
import getComponentByName from './componentLoaderByName'
import PortalThemeProvider from 'shared-ui/assets/styles/PortalThemeProvider'
import MsalB2CAuthProvider from './auth/MsalB2CAuthProvider'

const apiUrl = process.env.REACT_APP_API_URL
const apiConnector = new ApiConnector(apiUrl)
const portalApi = new PortalApi(apiConnector)

const history = createBrowserHistory()

export const HistoryContext = React.createContext(history)

export default function App(
  { msalInstance, data, data: { spaRedirectURL = [] } = {} } = { data: {} },
) {
  return (
    <Router history={history}>
      <HistoryContext.Provider value={history}>
        <Route
          render={({ location, history }) => (
            <PortalThemeProvider>
              <ErrorHandler>
                <>
                  <MsalB2CAuthProvider instance={msalInstance} data={data} api={portalApi}>
                    <ApiProvider api={portalApi}>
                      <SnackbarProvider maxSnack={4} dense>
                        <SnackMessages />
                        <PageWrapper
                          getComponentByName={getComponentByName}
                          AppFrame={AppFrame}
                          PopupWrapper={PopupWrapper}
                          location={location}
                          history={history}
                          pathExclusions={spaRedirectURL}
                        />
                      </SnackbarProvider>
                    </ApiProvider>
                  </MsalB2CAuthProvider>
                </>
              </ErrorHandler>
            </PortalThemeProvider>
          )}
        />
      </HistoryContext.Provider>
    </Router>
  )
}
