import { getUnitConversion, getProductPU } from '~components/molecules/OrdersModule/orderUtils'

let CURRENCY_CODE = 'EUR'

export default class AnalyticsEvents {
  static userId = null
  static pageCategory = null
  static containerLoaded = false
  static page = ''
  static rawPage = ''

  static init(gtmId, gtmSettings, currency, addTags) {
    window.dataLayer = window.dataLayer || []
    currency && (CURRENCY_CODE = currency.toUpperCase())

    if (AnalyticsEvents.containerLoaded === true) return
    AnalyticsEvents.containerLoaded = true

    const { rowData = [] } = gtmSettings || {}
    const settings = '' + rowData.map(({ key = '', value = '' }) => `&${key}=${value}`).join('')

    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

    const mainBundle = document.getElementById('mainBundle')
    const nonce = mainBundle.getAttribute('nonce') || mainBundle.nonce

    const gtmScriptTag = document.createElement('script')
    gtmScriptTag.async = true
    gtmScriptTag.id = 'gtmScript'
    gtmScriptTag.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + settings
    gtmScriptTag.setAttribute('nonce', nonce)
    gtmScriptTag.setAttribute('data-nonce', nonce)
    document.body.append(gtmScriptTag)

    if (addTags) {
      const connexTagManagerDiv = document.createElement('div')
      connexTagManagerDiv.style = 'display: none; visibility: hidden;'
      connexTagManagerDiv.id = 'cnnxsTags'
      connexTagManagerDiv.innerHTML = decodeURIComponent(escape(window.atob(addTags))).replace(
        /{{nonce}}/gi,
        nonce,
      )
      document.body.append(connexTagManagerDiv)
      connexTagManagerDiv.querySelectorAll('script').forEach(script => {
        const clone = document.createElement('script')
        for (const attr of script.attributes) clone.setAttribute(attr.name, attr.value)
        clone.text = script.innerHTML
        clone.nonce = nonce
        connexTagManagerDiv.removeChild(script)
        connexTagManagerDiv.append(clone)
      })
    }
  }

  static firstPageLoadComplete = false

  static dataPush(member, pageData) {
    window.dataLayer = window.dataLayer || []

    const { _id: userId, contactType } = member || {}
    userId && (AnalyticsEvents.userId = userId)

    const {
      componentName,
      props: { _link: { _sectionId, _pageCategory } = {}, targetGroups = [] } = {},
    } = pageData || {}
    const pageCategory = _pageCategory || _sectionId || 'none'
    pageCategory !== 'none' && (AnalyticsEvents.pageCategory = pageCategory)
    !AnalyticsEvents.pageCategory && (AnalyticsEvents.pageCategory = 'other')

    const rawPage = window.location.pathname + (window.location.hash || '')
    const page = rawPage.replace('#', '/').replace(/\/\//g, '/')

    if (AnalyticsEvents.firstPageLoadComplete) {
      if (page === AnalyticsEvents.page) return

      const [, prevHash] = AnalyticsEvents.rawPage.split('#')
      const [, newHash] = rawPage.split('#')

      AnalyticsEvents.page = page
      AnalyticsEvents.rawPage = rawPage

      if (prevHash && !newHash) {
        // triggered by a closing lightbox, no need to track
        return
      }

      window.dataLayer.push({
        event: 'updatevirtualpath',
        pageCategory: AnalyticsEvents.pageCategory,
        userId,
        page,
        virtualDocumentPath: page,
        targetGroups: targetGroups.map(t => t.id).join(','),
        userType: contactType,
      })
    } else {
      //Default pageview
      window.dataLayer.push({
        userType: contactType,
        pageCategory: AnalyticsEvents.pageCategory,
        page,
        userId,
      })
      AnalyticsEvents.firstPageLoadComplete = true
    }

    // Notifying Google Optimize that the page is fully mounted and rendered
    window.dataLayer.push({
      event: 'optimize.activate',
      page,
    })

    switch (componentName) {
      case 'ProductDetailTemplate': {
        const {
          props: {
            crmProduct,
            priceListPrice = 0,
            selectedDistributor: { distributorCode = '' } = {},
          } = {},
        } = pageData || {}

        this.pageCategory = 'product'
        this.pushProduct(crmProduct, priceListPrice * getProductPU({ crmProduct }), distributorCode)
        break
      }
      case 'ProductOverviewTemplate': {
        const { props: { elements } = {} } = pageData || {}

        this.pushProductList('', elements)
        break
      }
      case 'OrdersTemplate': {
        const { props: { distributorCode, assignedDistributors = [] } = {} } = pageData || {}

        const selectedDistributor = assignedDistributors.find(
          dist => dist.distributorCode === distributorCode,
        )

        if (selectedDistributor && selectedDistributor.products)
          this.pushProductList(distributorCode, selectedDistributor.products)
        break
      }
      case 'SpecialOffersOverviewTemplate': {
        const { props: { elements } = {} } = pageData || {}

        this.pushPromoView(
          elements.map(({ _id, type, title }, index) => {
            return {
              creative: type,
              id: _id,
              name: title,
              position: index + 1,
            }
          }),
        )
        break
      }
    }
  }

  static formatProducts(products, useQuantity, usePosition = true, additional) {
    return products
      .map((p, i) => {
        if (!p.crmProduct) return

        let formattedProduct = {
          ...AnalyticsEvents.transformProduct(p.crmProduct, p.priceListPrice),
          ...additional,
        }
        useQuantity && (formattedProduct.quantity = p.amount * getUnitConversion(p))
        usePosition && (formattedProduct.position = i + 1)
        return formattedProduct
      })
      .filter(Boolean)
  }

  static pushProduct(product, price, list) {
    const currencyCode = CURRENCY_CODE
    const products = [{ ...AnalyticsEvents.transformProduct(product, price), list }]

    AnalyticsEvents.pushEvent('eeProductDetail', { currencyCode, products })
  }

  static transformProduct(product, price) {
    if (!product) return

    const {
      brandName: brand,
      tobaccoProductCode: id,
      tobaccoProductName: name,
      tobaccoCategory: category,
      productVariety: variant,
    } = product

    return {
      brand,
      category,
      id,
      name,
      variant,
      price: price || 0,
    }
  }

  static pushContest(contestCategory, contestName) {
    AnalyticsEvents.pushEvent('contest', { contestCategory, contestName })
  }

  static pushContactEvent(data) {
    const { form: { _id } = {} } = data || {}
    const requestType = _id && _id === 'support' ? 'support' : 'sales'

    AnalyticsEvents.pushEvent('contactRequest', { requestType })
  }

  static pushEvent(event, data = {}) {
    const page = AnalyticsEvents.page
    const virtualDocumentPath = AnalyticsEvents.page
    const pageCategory = AnalyticsEvents.pageCategory
    const userId = AnalyticsEvents.userId

    window.dataLayer.push({ event, page, pageCategory, virtualDocumentPath, userId, ...data })
  }

  static dashboardTileClick(props) {
    const tileCategory = props._pageCategory || ''
    const tileName = props.headline || props.label || ''

    AnalyticsEvents.pushEvent('clickTile', { tileCategory, tileName })
  }

  static dashboardCarouselClick(data, type) {
    const { link: { _sectionId, _pageCategory, slug } = {} } = data
    const interactionType = type || 'click'
    const carouselLink = slug
    const carouselCategory = _pageCategory || _sectionId

    AnalyticsEvents.pushEvent('clickCarousel', {
      interactionType,
      carouselLink,
      carouselCategory,
    })
  }

  static pushAddToCart(product, quantity, price) {
    let data = {
      currencyCode: CURRENCY_CODE,
      products: [{ ...this.transformProduct(product, price), quantity }],
    }
    AnalyticsEvents.pushEvent('addToCart', data)
  }

  static pushRemoveFromCart(product, quantity, price) {
    let data = {
      currencyCode: CURRENCY_CODE,
      products: [{ ...this.transformProduct(product, price), quantity }],
    }
    AnalyticsEvents.pushEvent('removeFromCart', data)
  }

  static pushProductList(distributorCode, products) {
    let data = {
      currencyCode: CURRENCY_CODE,
      list: distributorCode,
      products: AnalyticsEvents.formatProducts(products).map(p => ({
        ...p,
        list: distributorCode,
      })),
    }
    AnalyticsEvents.pushEvent('eeListView', data)
  }

  static pushProductListClick(distributorCode, product, position) {
    position = (position + 1).toString()
    let data = {
      list: distributorCode,
      products: AnalyticsEvents.formatProducts([product], false, false, {
        position,
        list: distributorCode,
      }),
    }
    AnalyticsEvents.pushEvent('eeListClick', data)
  }

  static pushPurchase(order, products, optionalComment) {
    let data = {
      currencyCode: CURRENCY_CODE,
      transactionId: order._id,
      transactionTotal: (order.priceTotal || '0').toString().replace(/[^0-9.,]/g, ''),
      products: AnalyticsEvents.formatProducts(products, true, false),
      optionalComment,
    }
    AnalyticsEvents.pushEvent('purchase', data)
  }

  static pushProductFilter(filterCategory, valueSelected) {
    AnalyticsEvents.pushEvent('filters', { filterCategory, valueSelected })
  }

  static pushDocumentDownload(documentName, documentType) {
    AnalyticsEvents.pushEvent('documentDownload', {
      documentName,
      documentType,
    })
  }

  static pushHelpTopic(helpTopic) {
    AnalyticsEvents.pushEvent('documentDownload', { helpTopic })
  }

  static pushOrderHistoryClick() {
    AnalyticsEvents.pushEvent('orderHistoryClick')
  }

  static pushTrialsHistoryClick() {
    AnalyticsEvents.pushEvent('trialsHistoryClick')
  }

  static pushVideoCompletionRate(videoName, videoCompletion) {
    AnalyticsEvents.pushEvent('videoCompletion', {
      videoName,
      videoCompletion,
    })
  }

  static pushBrandClick(brand, position) {
    AnalyticsEvents.pushEvent('brandsClick', {
      brandClick: brand,
      brandPosition: position,
    })
  }

  static pushPromoView(specialOffersAnalytics) {
    AnalyticsEvents.pushEvent('eePromoView', { promotions: specialOffersAnalytics })
  }

  static pushPromoClick(specialOffersAnalytics) {
    AnalyticsEvents.pushEvent('eePromoClick', { promotions: specialOffersAnalytics })
  }

  static pushSpecialOfferShoppingCart(name, quantity) {
    AnalyticsEvents.pushEvent('specialOfferShoppingCart', {
      specialOfferName: name,
      specialOfferQuantity: quantity,
    })
  }

  static pushMyRewards(tile, points, rank) {
    AnalyticsEvents.pushEvent('myRewardsClickTile', {
      tileName: tile,
      jtiPoints: points,
      rank: rank,
    })
  }

  static pushClickOnTile(page, tileCategory, tile) {
    AnalyticsEvents.pushEvent('clickTile', {
      tileCategory: tileCategory,
      tileName: tile,
    })
  }

  static pushChatBotOpen() {
    AnalyticsEvents.pushEvent('openChatbot')
  }

  static pushRelatedContentClick(tileCategory, tileName) {
    AnalyticsEvents.pushEvent('relatedContentClick', {
      tileCategory,
      tileName,
    })
  }

  static pushRewardOrder(rewardName, rewardPrice, rewardQuantity) {
    AnalyticsEvents.pushEvent('rewardOrder', {
      rewardName,
      rewardPrice,
      rewardQuantity,
    })
  }

  static pushRewardClick(rewardName, rewardPrice, rewardPosition) {
    AnalyticsEvents.pushEvent('rewardClick', {
      rewardName,
      rewardPrice,
      rewardPosition,
    })
  }

  static pushRewardProgressClick() {
    AnalyticsEvents.pushEvent('rewardProgressClick')
  }
}
